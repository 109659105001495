
























































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
@Component({
  name: 'StatusMonitoring',
  filters: {
    formatTimestamp(date: number | any) {
      return formatTimestamp(date)
    },
  },
})
export default class StatusMonitoring extends VueBase {
  private blackListContent = `
HTTP请求信息如下：
GET /index.html?action=login

HTTP/1.1
Host:www.demo.cn
Referer:https://www.demo,cn
User-Agent:Mozilla/5.0 (Windows NT 6.1; Win64; x64)
Content-Type:application/x-www-form-urlencoded

对应字段：
URL（不包含URL参数）:https://www.demo.cn/index.html
URLPath:/index.html?action=login
Header key: Content-Type
Header value: application/x-www-form-urlencoded`

  private reflashFlag = false

  private healthData = {
    dongtai_openapi: {
      status: 0,
    },
    dongtai_engine: {
      status: 0,
    },
    oss: {
      status: 0,
    },
    engine_monitoring_indicators: [],
  }

  private async getHealth() {
    this.loadingStart()
    const res = await this.services.setting.health()
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.healthData = res.data
  }
  private async reflash() {
    this.loadingStart()
    const res = await this.services.setting.ossHealth()
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.healthData.oss = res.data.oss
  }
  // 保持变更

  created() {
    this.getHealth()
  }
}
